#chat-container {
  margin-left: 10px;
  height: 400px;
  overflow: auto;
}

#notes-container {
  /* margin-left: 10px; */
  margin-top:16px;
  max-height: 400px;
  overflow: auto;
}

#chat-container-empty {
  margin-left: 10px;
  height: 10px;
  overflow: auto;
}
/* 
.admin-notes {
  background-color: #E5E5E5 !important;
}

.user-notes {
  background-color: #cbcbcb !important;
} */

/* .flexChild {
  display: inline-block;
  vertical-align: middle;
} */

.flexChildText {
  float: left;
  width: 50%;
  margin-bottom: 30px;
}

.flexChildButton{
  float: right;
}

.bs-chat-area{
  padding-left: 15% !important;
  padding-right: 15% !important;
}

.chat-bubble{
  border-radius: 16px;
  border: 1px solid ;
  padding: 8px 16px;
  margin-bottom: 24px;
}

.public-bubble{
  background-color: var(--shaleBlue05);
  color: var(--whisperGrey);
  border-color: var(--shaleBlue02)
}

.worknote-bubble{
  background-color: #cfa408;
  color: var(--whisperGrey);
  border-color: #f7db74;
}

#public-msg-btn{
  background-color: var(--shaleBlue05);
  color: white;
}

#internal-msg-btn{
  background-color: #cfa408;
  color: var(--whisperGrey);
  margin-left: 10px;
  /* border-color: #f7db74; */
}


