/* FONTS IMPORT */

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;0,800;1,400&display=swap');

/* CSS VARIABLES */

:root {
	--shaleBlue: #0577B1;
	--shaleBlue01: #23B1F9;
	--shaleBlue02: #86D4FC;
	--shaleBlue03: #EAF7FE;
	--shaleBlue04: #04567F;
	--shaleBlue05: #02344E;
	--shaleBlue06: #029FEF;
	--dukeBlue: #012169;
	--whisperGray: #F3F2F1;
	--castIron: #262626;
	--graphite: #666666;
	--granite: #b5b5b5;
	--gradient01: linear-gradient(
		to bottom right,
		var(--shaleBlue),
		var(--shaleBlue01),
    var(--shaleBlue04)
	);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

footer{
    background-color: var(--dukeBlue);
    min-height: 280px;
    color:var(--whisperGray);
    padding: 32px 24px;
    z-index: 100;
}

footer p{
    color:var(--whisperGray);
    text-align: center;
}

footer a, footer a:hover{
    color:var(--shaleBlue02);
}

footer img{
    max-width: 200px;
    display: block;
    margin: 0 auto 24px auto;
}

@media only screen and (min-width: 576px) {
    footer{
        min-height: 150px;
    }
}