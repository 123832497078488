.nav-item .nav-link .ms-1 {
    color: white;
}

.nav-item .nav-link .bi {
    color: white;
}

.table-responsive-stack tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}


.table-responsive-stack td,
.table-responsive-stack th {
    display: block;
    /*      
     flex-grow | flex-shrink | flex-basis   */
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.table-responsive-stack .table-responsive-stack-thead {
    font-weight: bold;
}

/* .table td,
.table th {
    white-space: nowrap;
    width: 1%;
} */

.modal.show {
    display: block;
}

.is-blurred {
    filter: blur(2px);
    -webkit-filter: blur(2px);
}


/* #colored_tab {
    background-color: #2b6efd;
    color: white;
} */

#hoverable_tab:hover {
    color: black;
}

/* NEW CSS */


.admin-side .red-star {
    color: red;
}


.admin-side.container-fluid{
    padding: 0;
    font-family: 'Open Sans', sans-serif;
}

.admin-side .admin-main{
    padding: 48px 24px 48px 132px;
}


.admin-main{
    min-height:76vh;
}

/* Nav */

.admin-nav{
    background-color: var(--dukeBlue);
    width: 108px;
    height: 100vh;
    position: fixed;
    z-index: 10;
}

.admin-nav>div{
    padding: 48px 16px;
}

.admin-nav ul{
    padding: 0;
    margin: 0 0 24px 0;
    list-style-type: none;
    margin: 0;
}

.admin-nav li, .admin-nav .back-link{
    padding: 0;
    margin: 0 0 24px 0;
    text-align: center;
}

.admin-nav .back-link span{
    display: none;
}

.admin-nav li:last-of-type{
    margin: 0;
}

.admin-nav svg path, .admin-nav a, .admin-nav span, .admin-nav i{
    color: var(--whisperGray);
    font-size: 16px;
    text-decoration: none;
}

.admin-nav a:hover svg path, .admin-nav a:hover span, .admin-nav a:hover i {
    color: var(--shaleBlue02);
    font-weight: 600;
}

.admin-nav .back-link {
    color: var(--whisperGray);
    display: block;
}

.admin-nav div>span{
    margin-bottom: 24px;
    display: block;
}

.admin-nav .btn.btn-outline-success {
    background-color: var(--whisperGray);
    color: var(--shaleBlue);
    border: none;
    font-weight: 600;
    display: inline-block;
}

.admin-nav .btn.btn-outline-success:hover{
    background-color: var(--shaleBlue02);
    color: var(--castIron);
}

.admin-nav .admin-name {
    position: absolute;
    bottom: 48px;
}

@media only screen and (min-width: 576px) {
    .admin-nav{
        width: 196px;
    }

    .admin-side .admin-main{
        padding: 48px 24px 48px 220px;
    }

    .admin-nav li, .admin-nav .back-link{
        text-align: left;
    }

    .admin-nav li a, .admin-nav .back-link{
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
    }

    .admin-nav .back-link{
        align-items: start;
        margin-bottom: 48px;
        text-transform: uppercase;
    }

    .admin-nav .back-link span{
        display: block;
    }

    .admin-nav a>span{
        padding-left: 12px;
        margin: 0 !important;
    }

  }

/*SUB PAGES */
.admin-side .link-info{
    color: var(--shaleBlue06) !important;
    display: block;
    text-decoration: none;
    font-weight: 600;
}

.admin-side .link-info:hover{
    color: var(--shaleBlue) !important;
    text-decoration: underline;
    
}

.admin-side .row.g-3{
    display: block;
    margin-top: 24px;
}

.admin-side .btn.btn-primary.admin-addon-btn, .admin-side .btn.btn-primary.btn-marginless{
    margin: 0 0 24px 0;
}

.admin-side .admin-main>.form-label{
    display: none;
}

  /* TABLES */

  .admin-side .btn.btn-primary, .admin-side .btn.btn-smaller{
    background-color: var(--shaleBlue);
    color: var(--whisperGray);
    box-shadow: 1px 1px 4px var(--graphite);
    margin: 8px;
    border: none;
    font-weight: 400;
}

.admin-side .btn.btn-link {
    color: var(--shaleBlue);
}

.admin-side .btn.btn-primary:hover, .admin-side .btn.btn-smaller:hover{
    background-color: var(--shaleBlue02);
    color: var(--castIron);
}

.admin-side .beUiVL{
    font-weight: 800;
    font-size: 16px;
}

.admin-side .dwPlXY{
    font-size: 16px;
}

.admin-side th {
    font-weight: 800;
}

.admin-side td, .admin-side th {
    max-width: 100px;
}

.admin-side div[data-tag="allowRowEvents"]:hover {
    overflow: visible;
    z-index: 10;
    background-color: white;
}

/* view job page */


.admin-side .admin-main-grid>div{
    width: 100%;
} 

.job-detail-header{
    display: grid;
    grid-template-columns: 1fr auto auto;
    place-items: start;
    margin-bottom: 32px;
}

.job-detail-header h1, .job-detail-header h2{
    grid-column: 1/4;
}

.job-detail-header span{
    grid-column: 1/2;
    align-self: center;
}




.job-detail-header .assign-to{
    margin: 8px 0;
    grid-column: 1/4;
}

.job-detail-header .assign-to .btn.btn-primary{
    margin-left: 32px;
}

.admin-side .desktop-tabs{
    display: none;
}


.admin-side a, .admin-side .nav-tabs path{
    color: var(--shaleBlue06);
    font-weight: 600;
    text-decoration: none;
}

.admin-side .nav-tabs{
    margin-bottom: 24px;
}

.admin-side .nav-tabs .nav-link.active, .admin-side .nav-tabs .nav-link.active path{
    color: var(--castIron);
    background: none;
}

.admin-side .nav-tabs .nav-link:hover, .admin-side .nav-tabs .nav-link:hover path{
    color: var(--castIron);
    background: var(--shaleBlue03);
}

.admin-side .TabContent>div{
    margin-bottom: 24px;
}

.admin-side .TabContent>div p{
    margin-bottom: 8px;
}

.admin-side .TabContent>div th{
    padding-left: 0;
}

.admin-side .TabContent h3{
    margin: 24px 0;
}

.admin-side .table button {
    margin-left: 24px;
    padding: 0 10px 3px 10px;
    color: var(--shaleBlue06);
    font-weight: 600;
    border: 2px solid var(--shaleBlue02);

}

.admin-side .btn-remove{
    background-color: transparent !important;
}

.admin-side .input-group.mb-3 button {
    border-radius: 0 8px 8px 0;
    border: 1px solid var(--shaleBlue);
    background-color: var(--shaleBlue);
    color: var(--whisperGray);
}

.admin-side .input-group.mb-3 {
    display: grid;
    grid-template-columns: 1fr 100px;
    column-gap: 0;
}

.admin-side .btn-outline-secondary{
    background-color: var(--shaleBlue);
    color: var(--whisperGray);
    border:  1px solid var(--shaleBlue);
}

.admin-side .btn-outline-secondary:hover{
    background-color: var(--shaleBlue03);
    color: var(--castIron);
    border:  1px solid var(--shaleBlue02);
}

#endActAs{
    background-color: var(--shaleBlue);
    color: var(--whisperGray);
    border:  1px solid var(--shaleBlue);
}

#endActAs:hover{
    background-color: var(--shaleBlue03);
    color: var(--castIron);
    border:  1px solid var(--shaleBlue02);
}


.admin-side .tab-content-titles h3{
    margin:0;
}

.admin-side .tab-content-titles{
    margin-bottom:24px;
}


.admin-side .btn.btn-primary.btn-margin-fix{
    margin-top:0;
    margin-bottom: 24px;
}

.admin-side .TabContent table th{
    width:auto;
    max-width: 150px;
    min-width: 20px;
}


@media only screen and (min-width: 576px) {
    .admin-side .desktop-tabs{
        display: block;
    }
    .admin-side .mobile-tabs{
        display: none;
    }
}


@media only screen and (min-width: 1000px) {
    .admin-side .admin-main-grid{
        display: grid;
        grid-template-columns: 2fr 1fr;
        align-items: start;
        justify-items: start;
        column-gap: 32px;
    }

    /* .admin-side .admin-main-grid .col{
        grid-column: 2/3;
    } */

    .admin-side .job-detail {
        margin-bottom: 24px;
    }
}

/* Flash for emails being suspended */

.admin-side .flash-button-wrapper span{
    font-weight: 800;
}

.admin-side .flash-button-wrapper .btn.btn-primary{
    background-color: #e3ac14;
    color: var(--castIron);
    /* border: 1px solid var(--graphite); */
    /* border: 1px solid #e3ac14; */
    /* box-shadow: none; */
    box-shadow: 0px 0px 4px var(--castIron);
    margin-left: 32px;
    font-weight: 400;
}

.admin-side .flash-button-wrapper .btn.btn-primary:hover{
    background-color: #f7db74;
    /* background-color: transparent; */
    color: var(--castIron);
    /* border: 1px solid var(--granite); */
    border: none;
    box-shadow: 0px 0px 4px var(--graphite);
}

/* chat view */

.admin-side .chat-section{
    border-top: 1px solid var(--granite);
    padding-top: 24px;
    margin-top: 48px;
}

.admin-side #chat-container{
    margin: 0;
}

.admin-side .mb-3 {
    display:grid;
    grid-template-columns: 1fr 50px;
    column-gap: 16px;
}

.admin-side form .mb-3 {
    display:block;
    max-width: 970px;
}

.admin-side form label {
    font-weight: 600;
}

.admin-side .mb-3 label{
    grid-column: 1/3;
}

.admin-side .flexChildText, .admin-side .flexChildButton {
    float: none;
    width: 100%;
}
.admin-side .flexChildText textarea{
    border-radius: 8px;
    height: 100px;
}

.admin-side .bs-chat-area {
    padding: 8px !important;
}

.flexChildButton .bi.bi-send{
    color: var(--whisperGray);
}

.flexChildButton .btn.btn-primary:hover{
    background-color: var(--shaleBlue02);
}

.flexChildButton .btn.btn-primary:hover .bi.bi-send{
    color: var(--castIron);
}

.admin-side #chat-container{
    height: auto;
    max-height: 400px;
    overflow-y: scroll;
    margin-bottom: 24px;
}

.admin-side #round{
    all: unset;
}

.admin-side .card-body{
    border: 1px solid var(--shaleBlue02);
    background-color: var(--shaleBlue03);
    color: var(--castIronwww);
    padding: 8px 16px;
    border-radius: 16px;
    margin-bottom: 24px;
}

.admin-side .card-body.admin-notes{
    border: 1px solid #f7db74;
    background-color: #fff2c2;
}

.admin-side .card-body.user-notes{
    border: 1px solid #f7db74;
    background-color: #f7db74;
}

.admin-side .card:last-of-type .card-body{
    margin-bottom: 0;
}

.admin-side .card-body .card-title{
    margin: 0;
    padding-bottom: 8px;
}

.admin-side .card-body p:last-of-type{
    padding-bottom: 0;
}


.admin-side .card-body{
    width: 90%; 
}

.admin-side .user-chat.mb-3.text-bg-primary, .admin-side .admin-chat.mb-3.text-bg-primary{
    display:block;
    background-color: transparent !important;
    border: none !important;
}

/* not sure if we need to invert the behavior on this one */

.admin-side .user-chat .card-body{
    background-color: var(--shaleBlue05);
    color: var(--whisperGray);
}

.admin-side .user-chat .card-body.user-notes{
    border: 1px solid #f7db74;
    background-color: #cfa408;
}

.admin-side .user-chat .card-body p, .admin-side .user-chat .card-body b{
    color: var(--whisperGray);
}

.admin-side .admin-chat .card-body{
    margin-left: 10%;
}

@media only screen and (min-width: 1000px) {
    .admin-side .chat-section{
        border-left: 1px solid var(--granite);
        border-top: none;
        padding-left: 24px;
        padding-top: 0;
        align-self: stretch;
    }
}

/* material additional field views */

.tooltip-container {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
/* Tooltip container */
.tooltip-container .tooltip-text {
    display: none;
    width: 50px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -30px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip-container .tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip-container:hover .tooltip-text{
    display: block;
    opacity: 1;
}

.mb-3 .tox .tox-editor-header{
    z-index: 0;
}

.btn.btn-light{
    border: 1px solid var(--granite);
}
.rowC {
    display:flex; 
    flex-direction:row;
    margin-top: 5px;
}

.bs-job-check{
  color: #28a745 !important;
}

.bs-job-uncheck{
  color: #dc3545
}

.bs-job-check-option{
  cursor: pointer;
  display: table;
  padding: 5px 10px;
  margin: 0;

  border-radius: 25px;
}

.bs-job-check-option:hover{
  cursor: pointer;
  background-color: var(--dukeBlue);
  color: whitesmoke;
}

.row-btn{
  margin-left: 5px;
}

#root:has(#flash-message) .admin-main{
    padding-top: 118px;
}

#flash-message {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: fixed;
    z-index: 10;
    width: 100%;
  }
  
  #endActAs {
    margin-left: 10px;
  }

  .react-datepicker-popper {
	z-index: 9999 !important;
}

.date-label {
    margin-right: 10px; /* Adds space between the label and the input box */
  }

/*Sadiyas added css to the calendar input boxes */

    .input-group.row>div {
    display: grid;
    justify-content: start;
    align-items: center;
    gap: 24px;
    margin-bottom: 16px;
    margin-top: 40px;
    }

    @media only screen and (min-width: 700px) {
        .input-group.row>div {
          grid-template-columns: repeat(2, minmax(auto,300px));
        }
      }

    .input-group.row label, .input-group.row .react-datepicker-wrapper {
        display: block;
        max-width: 300px;
        min-width: 200px;
    }

    .input-group.row label{
        margin-bottom: 8px;
    }
