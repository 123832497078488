.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Not Found Page CSS */
.not-found {
  min-height: 80vh;
  display: grid;
  place-items: center;
}

.w-75 {
  width: 75%!important;
}

.text-center {
  text-align: center!important;
}

.not-found .link-button {
  color: #fff;
  background-color: #012169;
  border-radius: 0.5rem;
  border: 0.0625rem solid #262b40;
  box-shadow: inset 0 1px 0 hsla(0,0%,100%,.15),0 1px 1px rgba(46,54,80,.075);
  padding: 0.55rem 0.75rem;
  cursor: pointer;
  text-align: center;
  margin: 16px;
  display: inline-block;
}

.not-found .row {
  max-width: 800px;
  padding: 32px;
}